import {NgModule} from '@angular/core';
import {IndexComponent} from './index/index.component';
import {SearchComponent} from './search/search.component';
import {Routes, RouterModule } from '@angular/router';
import {ContactComponent} from './contact/contact.component';
import {TermsComponent} from './terms/terms.component';
import { BookComponent } from './book/book.component';
import {ProfileComponent} from './profile/profile.component';
import { NotFoundComponent } from './not-found/not-found.component';

const appRoutes : Routes   = [
  {path : '', component : IndexComponent },
  {path : 'search', component : SearchComponent },
  {path : 'terms', component : TermsComponent },
  {path : 'contact', component : ContactComponent },
  {path : 'book', component : BookComponent },
  {path : 'profile', component : ProfileComponent },
  {path : '**', component : NotFoundComponent}

];

@NgModule ({
  imports : [RouterModule.forRoot(appRoutes)],
  exports : [RouterModule]
})

export class AppRoutingModule {

}
