import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { SearchComponent } from './search/search.component';
import { DetailComponent } from './detail/detail.component';
import { HeaderComponent } from './blocks/header/header.component';
import { IndexHeaderComponent } from './blocks/index-header/index-header.component';
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { BookComponent } from './book/book.component';
import { ProfileComponent } from './profile/profile.component';
import { FotterComponent } from './blocks/fotter/fotter.component';
import { NotFoundComponent } from './not-found/not-found.component';




@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SearchComponent,
    DetailComponent,
    HeaderComponent,
    IndexHeaderComponent,
    ContactComponent,
    TermsComponent,
    BookComponent,
    ProfileComponent,
    FotterComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
